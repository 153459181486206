/* eslint-disable @nx/enforce-module-boundaries */
import { ChevronDownIcon } from '@chakra-ui/icons';
import { ArrowDown, CloseIcon } from '@mybridge/icons';
import { Button, Radio, RadioGroup, Stack } from '@mybridge/ui';
import { forwardRef, useMemo, useState, useContext, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FilterPopover } from 'v4/components/filter-popover';
import { useRadioGroup } from '@chakra-ui/react';
import { CustomRadioButton } from 'v4/components/custom-radio-checkbx';
import { SearchContext } from '../../../snippets/search/context';
import styles from '../../filter-popover/filter-popover.module.scss';
export const SearchFilterDatePosted = forwardRef(
  (
    {
      name = 'date_posted',
      title = 'Date Posted',
      onChange,
      value,
      defaultValue,
      ...props
    },
    ref
  ) => {
    const [selectedValue, setSelectedValue] = useState();
    const [popperOpen, setPopperOpen] = useState(false);
    const { resetFilters, setResetFilters } = useContext(SearchContext);
    const values = useMemo(
      () => [
        {
          title: 'Any time',
          value: 'any_time',
        },
        {
          title: 'Past 24 hours',
          value: 'past_24_hours',
        },
        {
          title: 'Past week',
          value: 'past_week',
        },
        {
          title: 'Past month',
          value: 'past_month',
        },
      ],
      []
    );

    const { handleSubmit, control } = useForm();

    useEffect(() => {
      selectedValue ? setResetFilters(true) : setResetFilters(false)
    }, [selectedValue]);

    useEffect(() => {
      if(!resetFilters){
        setSelectedValue();
        // setSearch();
      }
    }, [resetFilters]);

    const onSubmit = (form) => {
      const data_ = _.find(values, function(o){return o.value == form});
      onChange?.({[name]:form});
      setSelectedValue(form ? data_?.title : '');
      setPopperOpen(false);
    };

    const { getRadioProps, getRootProps } = useRadioGroup({
      onChange: onSubmit,
    })

    return (
      <>
        {/* <form onSubmit={handleSubmit(onSubmit)}> */}
          <FilterPopover
            trigger={
              <Button
                variant="transparent"
                fontSize="15px"
                color="brandGray.750"
                rightIcon={selectedValue ? '' : ""}
                justifyContent="space-between"
                w="100%"
              >
                 {selectedValue && resetFilters ? selectedValue : title}
                    {selectedValue && resetFilters ? <CloseIcon width="15" height="15" color="#3D5A80" cursor="pointer" onClick={() => onSubmit(null)} /> : <ArrowDown />}
              </Button>
            }
            onPopClose={(e) => setPopperOpen(e)}
          >
            <Controller
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Stack className={styles.filterList}>
                    {values?.map?.((v, vIndex) => (
                      // <Radio key={vIndex} value={v?.value}>
                      //   {v?.title}
                      // </Radio>
                      <CustomRadioButton key={vIndex} justifyContent="flex-start" {...getRadioProps({ value: v?.value })}>{v?.title}</CustomRadioButton>
                    ))}
                  </Stack>
                </RadioGroup>
              )}
              control={control}
              name={name}
            />
          </FilterPopover>
        {/* </form> */}
      </>
    );
  }
);
